import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import logo from "../../assets/images/logo.png"

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img src={logo} style={{ width: "250px", maxHeight: 125 }} title='Code Jays Inc' alt="Code Jays Inc" />
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            Home
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="#"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Services <i className='bx bx-chevron-down'></i>

                                        </Link>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Development and Design
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Webview
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    iOS/Android
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Backend
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Marketing
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Web 3.0/Crypto Development
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/about-us"
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            About Us
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="#"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Case Studies <i className='bx bx-chevron-down'></i>

                                        </Link>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="/case-study-hd-group"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Staffing Management Suite
                                                </Link>
                                            </li>
                                            {/* <li className="nav-item">
                                                <Link 
                                                    to="/case-study-entropia-invest" 
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Share Trading and Dividend Management
                                                </Link>
                                            </li> */}
                                            <li className="nav-item">
                                                <Link
                                                    to="/case-study-next-departure"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Mistake Flights Android/iOS App
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="#"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Pages <i className='bx bx-chevron-down'></i>

                                        </Link>
                                        {/* DropDown Menu */}
                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Development and Design
                                                </Link>
                                            </li>
                                            
                                        </ul> */}
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="#"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Blogs <i className='bx bx-chevron-down'></i>

                                        </Link>
                                        {/* DropDown Menu */}
                                        {/* <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="#"
                                                    activeClassName="active"
                                                    //onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Development and Design
                                                </Link>
                                            </li>
                                            
                                        </ul> */}
                                    </li>

                                    {/*  */}

                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    {/* <div className="option-item">
                                        <form className="search-box">
                                            <input type="text" className="input-search" placeholder="Search for anything" />
                                            <button type="submit">
                                                <i className="flaticon-loupe"></i>
                                            </button>
                                        </form>
                                    </div> */}

                                    <div className="option-item">
                                        <Link
                                            to="/contact"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i> Get A Quote <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
